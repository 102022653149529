export const BASEURL = 'https://api.learning.aseanenergy.org';
export const AUTH_URL = `${BASEURL}/auth-ace/`;

// logout
export const AUTH_URL_LOGOUT = `${BASEURL}/api-ace/logout/`;

// user
export const URL_USERS = `${BASEURL}/api-ace/user/`;

// reset password
export const URL_RESET_PASSWORD = `${BASEURL}/api-ace/password_reset/`;
export const URL_CHANGE_PASSWORD = `${BASEURL}/api-ace/password_reset/confirm/`;

// user role
export const URL_USERBYROLE = `${BASEURL}/api-ace/userbyrole/`;

// auth user
export const URL_TEACHER_REGIS = `${BASEURL}/auth-ace/users/me`;

// category & subcategory
export const URL_COURSETYPE = `${BASEURL}/api-ace/coursetype/`;
export const URL_CATEGORYCOURSE = `${BASEURL}/api-ace/categorycourse/`;
export const URL_SUBCATEGORYCOURSEBYCATEGORY = `${BASEURL}/api-ace/public/coursetype/`;

// course
export const URL_COURSE = `${BASEURL}/api-ace/course/`;

// topic
export const URL_TOPIC_BY_IDCOURSE = `${BASEURL}/api-ace/topicbyidcourse/`;
export const URL_TOPIC = `${BASEURL}/api-ace/topic/`;

// material
export const URL_MATERIAL_BY_IDTOPIC = `${BASEURL}/api-ace/materialbyidtopic/`;
export const URL_MATERIAL = `${BASEURL}/api-ace/material/`;

// quiz
export const URL_QUIZ_BY_IDTOPIC = `${BASEURL}/api-ace/quizbyidtopic/`;
export const URL_QUIZ = `${BASEURL}/api-ace/quiz/`;

// quiz question
export const URL_QUESTION_BY_IDQUIZ = `${BASEURL}/api-ace/questionbyidquiz/`;
export const URL_QUESTION = `${BASEURL}/api-ace/question/`;

// Live Session
export const URL_LIVE_SESSION = `${BASEURL}/api-ace/livesession/`;
export const URL_LIVE_SESSION_BY_IDTOPIC = `${BASEURL}/api-ace/livesessionbyidtopic/`;

// hero
export const URL_HEROBANNER = `${BASEURL}/api-ace/hero/`;

// forum
export const URL_GET_FORUM = `${BASEURL}/api-ace/forum/`;
export const URL_GET_FORUM_BYCOURSE = `${BASEURL}/api-ace/forumbyidcourse/`;

// discussion
export const URL_DISCUSSION = `${BASEURL}/api-ace/discussion/`;

// partners
export const URL_PARTNERSHIP = `${BASEURL}/api-ace/partner/`;

// enroll
export const URL_ENROLLEDCOURSE = `${BASEURL}/api-ace/enrolledcourse/`;

// review
export const URL_REVIEW = `${BASEURL}/api-ace/review/`;

// review
export const URL_FAQ = `${BASEURL}/api-ace/faq/`;

// forum
export const URL_FORUM = `${BASEURL}/api-ace/forum/`;

// student list
export const URL_STUDENT_LIST_CUSTOM = `${BASEURL}/api-ace/customenrolledbyidcourse/`;

// instructor
export const URL_INSTRUCTOR = `${BASEURL}/api-ace/instructor/`;
export const URL_INSTRUCTORMAPPING = `${BASEURL}/api-ace/instructormapping/`;
export const URL_INSTRUCTOR_BY_IDCOURSE = `${BASEURL}/api-ace/instructormappingbyidcourse/`;

// score quiz
export const URL_SCORE_BY_IDQUIZ = `${BASEURL}/api-ace/scorebyidquiz/`;

// certificate course
export const URL_CERTIFICATE = `${BASEURL}/api-ace/certificatecourse/`;
export const URL_CERTIFICATEPERMOUNTH = `${BASEURL}/api-ace/certificatestats/`;

// order
export const URL_ORDER = `${BASEURL}/api-ace/order/`;

// totalpaidorder
export const URL_TOTALPAIDORDER = `${BASEURL}/api-ace/totalpaidorder/`;

// paidorderstats
export const URL_PAIDORDERSTATS = `${BASEURL}/api-ace/paidorderstats/`;

// userbycountrystats
export const URL_USERBYCOUNTRYSTATS = `${BASEURL}/api-ace/userbycountrystats/`;

// totalenrollbycourse
export const URL_TOTALENROLLBYCOURSE = `${BASEURL}/api-ace/totalenrollbycourse/`;

// wishlist
export const URL_WISHLISTSTATS = `${BASEURL}/api-ace/wishlist/`;
